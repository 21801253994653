<template>
	<div class="pui-form">
		<booking-modals :modelName="modelName"></booking-modals>
		<pui-form-header v-if="modelLoaded" :showHeader="!isCreatingElement" class="mt-4">
			<booking-form-header :modelPk="modelPk"></booking-form-header>
		</pui-form-header>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-tabs v-model="tabmodel" class="ml-3 mb-3" slider-color="primary">
				<v-tab :key="0" :href="'#maintab'">{{ $t('form.booking.maintab') }}</v-tab>
				<v-tab :key="1" :href="'#documents'" v-if="!isCreatingElement && showDocuments">{{ $t('pui9.documents.tab') }}</v-tab>
			</v-tabs>
			<v-tabs-items v-model="tabmodel">
				<v-tab-item :key="0" lazy :value="'maintab'">
					<v-row class="mt-4 pui-form-layout">
						<v-col cols="12">
							<v-row dense>
								<v-col cols="3">
									<pui-select
										:id="`port-booking`"
										:attach="`port-booking`"
										:label="$t('form.booking.port')"
										:disabled="formDisabled"
										required
										toplabel
										clearable
										modelName="port"
										v-model="model"
										reactive
										:itemsToSelect="portItemsToSelect"
										:modelFormMapping="{ id: 'port' }"
										itemValue="id"
										itemText="portname"
										:fixedFilter="filterByUserPortsAndAuthority"
									></pui-select>
								</v-col>
								<v-col cols="5">
									<pui-select
										:id="`berth-booking`"
										:attach="`berth-booking`"
										:label="$t('form.booking.berth')"
										:disabled="formDisabled || !model.port"
										required
										toplabel
										clearable
										modelName="berth"
										v-model="model"
										reactive
										:itemsToSelect="berthItemsToSelect"
										:modelFormMapping="{ id: 'berth' }"
										itemValue="id"
										:itemText="(item) => (item.berthsubcode ? `${item.name} - ${item.berthsubcode}` : item.name)"
										:fixedFilter="berthFixedFilter"
									></pui-select>
								</v-col>
								<v-col cols="2">
									<pui-select
										:id="`bollardiniid-booking`"
										:attach="`bollardiniid-booking`"
										:label="$t('form.booking.bollardiniid')"
										:disabled="formDisabled || !model.berth"
										toplabel
										clearable
										modelName="bollard"
										v-model="model"
										reactive
										:itemsToSelect="bollardiniidItemsToSelect"
										:modelFormMapping="{ id: 'bollardiniid' }"
										itemValue="id"
										itemText="bolcode"
										:order="{ orderby: 'asc' }"
										:fixedFilter="bollardFixedFilter"
									></pui-select>
								</v-col>
								<v-col cols="2">
									<pui-select
										:id="`bollardendid-booking`"
										:attach="`bollardendid-booking`"
										:label="$t('form.booking.bollardendid')"
										:disabled="formDisabled || !model.berth"
										toplabel
										clearable
										modelName="bollard"
										v-model="model"
										reactive
										:itemsToSelect="bollardendidItemsToSelect"
										:modelFormMapping="{ id: 'bollardendid' }"
										itemValue="id"
										itemText="bolcode"
										:order="{ orderby: 'asc' }"
										:fixedFilter="bollardFixedFilter"
									></pui-select>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col cols="4">
									<pui-date-field
										:id="`eta-booking`"
										v-model="model.eta"
										:label="$t('form.booking.eta')"
										:disabled="formDisabled"
										required
										toplabel
										time
										:min="!formDisabled ? this.now : null"
									></pui-date-field>
								</v-col>
								<v-col cols="4">
									<pui-date-field
										:id="`etd-booking`"
										v-model="model.etd"
										:label="$t('form.booking.etd')"
										:disabled="formDisabled"
										required
										toplabel
										time
										:min="!formDisabled ? model.eta : null"
									></pui-date-field>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col cols="4">
									<pui-select
										:id="`companyid-booking`"
										:attach="`companyid-booking`"
										:label="$t('form.booking.companyid')"
										:disabled="formDisabled"
										required
										toplabel
										clearable
										v-model="model"
										modelName="company"
										:modelFormMapping="{ id: 'companyid' }"
										:itemsToSelect="companyidItemsToSelect"
										:itemValue="['id']"
										:itemText="(item) => `${item.name}`"
										:order="{ name: 'asc' }"
										:fixedFilter="filterCompany"
									></pui-select>
								</v-col>
								<v-col cols="3">
									<pui-select
										:id="`fromportcode-${modelName}`"
										:attach="`fromportcode-${modelName}`"
										:label="$t('form.booking.fromportcode')"
										:disabled="formDisabled"
										toplabel
										clearable
										required
										v-model="model"
										modelName="worldport"
										:modelFormMapping="{ code: 'fromportcode' }"
										:itemsToSelect="itemsToSelectFromPortCode"
										:itemValue="['code']"
										:itemText="(item) => `${item.code} - ${item.name} (${item.countrycode})`"
										reactive
									></pui-select>
								</v-col>
								<v-col cols="3">
									<pui-select
										:id="`toportcode-${modelName}`"
										:attach="`toportcode-${modelName}`"
										:label="$t('form.booking.toportcode')"
										toplabel
										clearable
										required
										:disabled="formDisabled"
										v-model="model"
										modelName="worldport"
										:modelFormMapping="{ code: 'toportcode' }"
										:itemsToSelect="itemsToSelectToPortCode"
										:itemValue="['code']"
										:itemText="(item) => `${item.code} - ${item.name} (${item.countrycode})`"
										reactive
									></pui-select>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col cols="3">
									<pui-select
										:id="`shiptype-booking`"
										:attach="`shiptype-booking`"
										:label="$t('form.booking.shiptype')"
										:disabled="formDisabled || model.ship != null"
										toplabel
										clearable
										modelName="shiptypepa"
										v-model="model"
										reactive
										:itemsToSelect="shiptypeItemsToSelect"
										:modelFormMapping="{ code: 'shiptype' }"
										itemValue="code"
										itemText="description"
										:order="{ description: 'asc' }"
										:fixedFilter="filterByPortAuthorityUser"
									></pui-select>
								</v-col>
								<v-col cols="5">
									<pui-select
										:id="`ship-booking`"
										:attach="`ship-booking`"
										:label="$t('form.booking.ship')"
										:disabled="formDisabled"
										toplabel
										clearable
										modelName="ship"
										v-model="model"
										reactive
										:itemsToSelect="shipItemsToSelect"
										:modelFormMapping="{ id: 'ship' }"
										:itemValue="['id']"
										:itemText="(item) => `${item.imo} - ${item.name}`"
										:order="{ name: 'asc' }"
										:fixedFilter="filterShipVersion"
									></pui-select>
								</v-col>
								<v-col cols="2">
									<pui-number-field
										:id="`length-booking`"
										v-model="model.length"
										:label="$t('form.booking.length')"
										:disabled="formDisabled || model.ship != null"
										toplabel
										integer
										required
										min="0"
										max="99999"
									></pui-number-field>
								</v-col>
								<v-col cols="2">
									<pui-number-field
										:id="`beam-booking`"
										v-model="model.beam"
										:label="$t('form.booking.beam')"
										:disabled="formDisabled || model.ship != null"
										toplabel
										integer
										min="0"
										max="99999"
									></pui-number-field>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col cols="12">
									<pui-select
										v-show="model.stop"
										:id="`stop-booking`"
										:attach="`stop-booking`"
										:label="$t('form.booking.stop')"
										disabled
										toplabel
										clearable
										modelName="stop"
										v-model="model"
										reactive
										:itemsToSelect="stopItemsToSelect"
										:modelFormMapping="{ id: 'stop' }"
										:itemValue="['id']"
										:itemText="(item) => getStopItemText(item)"
										:order="{ num: 'asc' }"
										detailComponentName="stop-form"
										detailModelName="stop"
									></pui-select>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-tab-item>
				<v-tab-item :key="1" :value="`documents`" v-if="!isCreatingElement && showDocuments">
					<pui-master-detail componentName="PuiDocumentGrid" :parentModelName="modelName" :parentPk="pk"></pui-master-detail>
				</v-tab-item>
			</v-tabs-items>
			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns :formDisabled="formDisabled" :saveDisabled="saving" :save="save" :back="back"></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import bookingActions from './BookingActions';
import bookingModals from './BookingModals.vue';
import BookingFormHeader from './BookingFormHeader.vue';

export default {
	name: 'booking-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		'booking-modals': bookingModals,
		BookingFormHeader
	},
	data() {
		return {
			modelName: 'booking',
			actions: bookingActions.formActions,
			ownlistships: false,
			now: new Date().toString()
		};
	},
	methods: {
		afterGetData() {
			if (this.model.stop) {
				this.formDisabled = true;
			}
			this.model.portauthority = this.$store.getters.getPortAuthorityId;

			const opts = {
				model: 'portauthority',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							field: 'id',
							op: 'eq',
							data: this.$store.getters.getPortAuthorityId
						}
					]
				}
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				if (response.data && response.data.data[0]) {
					//Fill data
					this.ownlistships = response.data.data[0].ownlistships;
				} else {
					this.ownlistships = false;
				}
			});
		},
		getStopItemText(item) {
			let startdate = item.ata ? item.ata : item.etaauth ? item.etaauth : item.eta;
			let enddate = item.atd ? item.atd : item.etdauth ? item.etdauth : item.etd;

			return `${item.portcallnumber} - ${item.num} - ${item.location} - ${item.vesselname} - ${this.$dateTimeUtils.getLocalFormattedDate(
				startdate,
				'DD/MM/YYYY HH:mm'
			)}  ${enddate ? '-' + this.$dateTimeUtils.getLocalFormattedDate(enddate, 'DD/MM/YYYY HH:mm') : ''} - ${item.consigneename} - ${
				item.status
			} - ${item.fromportname} - ${item.toportname}`;
		}
	},
	computed: {
		companyidItemsToSelect() {
			return [{ id: this.model.companyid }];
		},
		shipItemsToSelect() {
			return [{ id: this.model.ship }];
		},
		shiptypeItemsToSelect() {
			return [{ code: this.model.shiptype }];
		},
		portItemsToSelect() {
			return [{ id: this.model.port }];
		},
		berthItemsToSelect() {
			return [{ id: this.model.berth }];
		},
		bollardiniidItemsToSelect() {
			return [{ id: this.model.bollardiniid }];
		},
		bollardendidItemsToSelect() {
			return [{ id: this.model.bollardendid }];
		},
		stopItemsToSelect() {
			return [{ id: this.model.stop }];
		},
		itemsToSelectFromPortCode() {
			return [{ code: this.model.fromportcode }];
		},
		itemsToSelectToPortCode() {
			return [{ code: this.model.toportcode }];
		},
		filterByPortAuthorityUser() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'port_authority_id', op: 'eq', data: this.$store.getters.getPortAuthorityId }]
			};
		},
		filterByUserPortsAndAuthority() {
			const userPorts = this.$store.getters.getPortsIds;
			return {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'id', op: 'in', data: userPorts },
					{ field: 'portauthorityid', op: 'eq', data: this.$store.getters.getPortAuthorityId }
				]
			};
		},
		bollardFixedFilter() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'berthid', op: 'eq', data: this.model.berth }]
			};
		},
		filterShipVersion() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'next_version_id', op: 'nu', data: null },
					this.ownlistships
						? { field: 'port_authority_id', op: 'eq', data: this.$store.getters.getPortAuthorityId }
						: { field: 'port_authority_id', op: 'nu', data: null }
				]
			};
		},
		filterCompany() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'portauthorityid', op: 'eq', data: this.$store.getters.getPortAuthorityId },
					{ field: 'companytypeid', op: 'eq', data: 5 }
				]
			};
		},
		berthFixedFilter() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'type', op: 'eq', data: 1 },
					{ field: 'portid', op: 'eq', data: this.model.port }
				]
			};
		}
	},
	created() {
		this.$puiEvents.$on('onPuiSelect_selectedItems-ship-booking', (newVal) => {
			console.log(newVal);
			var lupaModel = newVal.model;
			if (lupaModel) {
				this.model.length = lupaModel.length;
				this.model.beam = lupaModel.beam;
				this.model.shiptype = lupaModel.shiptypepa;
			} else {
				this.model.length = null;
				this.model.beam = null;
				this.model.shiptype = null;
			}
		});
	}
};
</script>
